/**
 * @generated SignedSource<<cd9a9a1931632d164d50d2ce2aa1bd16>>
 * @relayHash 5e16a58c1afca3830f05116a66fe0106
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ferrum/483.0.0-2024-10-09T08:15:31.766Z/emailLoginLinkSendMutation

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EmailLoginLinkSendInput = {
  captchaKey?: string | null;
  captchaToken?: string | null;
  clientMutationId?: string | null;
  email: string;
  urlRedirect?: string | null;
};
export type emailLoginLinkSendMutation$variables = {
  input: EmailLoginLinkSendInput;
};
export type emailLoginLinkSendMutation$data = {
  readonly emailLoginLinkSend: {
    readonly clientMutationId: string | null;
  } | null;
};
export type emailLoginLinkSendMutation = {
  response: emailLoginLinkSendMutation$data;
  variables: emailLoginLinkSendMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EmailLoginLinkSendPayload",
    "kind": "LinkedField",
    "name": "emailLoginLinkSend",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "emailLoginLinkSendMutation",
    "selections": (v1/*: any*/),
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "emailLoginLinkSendMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "id": "ferrum/483.0.0-2024-10-09T08:15:31.766Z/emailLoginLinkSendMutation",
    "metadata": {},
    "name": "emailLoginLinkSendMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "8b94381d69ac2d40336bf6a85b15b14d";

export default node;
